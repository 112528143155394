import {
  Alert as AlertChakra,
  AlertProps,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  CloseButton,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { defaultValueAlerts, useAlert } from '~/contexts/alert/useAlert';

export const Alert: React.FC<AlertProps> = ({ ...props }) => {
  const { alertProps, setAlertProps } = useAlert();

  useEffect(() => setAlertProps(defaultValueAlerts), [setAlertProps]);

  return alertProps.isVisible ? (
    <AlertChakra
      data-cy="alert"
      w={props.width || { lg: 'xl', sm: 'sm' }}
      borderRadius="6"
      justifyContent="space-between"
      margin="auto"
      status={alertProps.status}
      {...props}
    >
      <AlertIcon />
      <Box flex="1">
        <AlertTitle>{alertProps.title}</AlertTitle>
        <AlertDescription>{alertProps.description}</AlertDescription>
      </Box>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={() => setAlertProps(defaultValueAlerts)}
      />
    </AlertChakra>
  ) : (
    <></>
  );
};
