export function WhalarLogo() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none">
      <path
        d="M56.1621 0.53244V14.9618C56.1621 14.9618 56.1621 22.1085 42.1227 22.1085C28.0834 22.1085 28.0834 28.9251 28.0834 29.2552C28.0834 28.9445 27.7811 22.1085 14.0441 22.1085C-0.46768 22.1085 0.00472149 14.9618 0.00472149 14.9618V0.53244C0.00472149 0.53244 0.00470723 7.67912 14.0441 7.67912C28.0834 7.67912 28.0834 14.3404 28.0834 14.8259C28.0834 14.185 28.2346 7.67912 42.1227 7.67912C56.0109 7.67912 56.1621 0.53244 56.1621 0.53244Z"
        fill="#02CACE"
      />
      <path
        d="M42.1226 33.6476C28.2343 33.6476 28.0832 40.309 28.0832 40.7944C28.0832 40.1536 27.4597 33.6476 14.0439 33.6476C0.628068 33.6476 0.00452956 26.5009 0.00452956 26.5009V40.9302C0.00452956 40.9302 -0.448976 48.0771 14.0439 48.0771C27.7808 48.0771 28.0832 54.8937 28.0832 55.2238C28.0832 54.913 28.3855 48.0771 42.1226 48.0771C55.8595 48.0771 56.1619 40.9302 56.1619 40.9302V26.5009C56.1619 26.3457 56.1619 33.6476 42.1226 33.6476Z"
        fill="#02CACE"
      />
    </svg>
  );
}
