import { BoxProps, Flex, FlexProps, Text, TextProps } from '@chakra-ui/react';

import { IconBox } from '~/components/IconBox';
import {
  TiktokNoBorderIcon,
  YoutubeNoBorderIcon,
  InstagramNoBorderIcon,
  SnapchatColorIcon,
} from '~/components/icons';
import { SOCIAL_PLATFORMS_NAMES } from '~/constants/social_platforms_names';

import { boxStyles, textStyles } from '../AuthorizationFooter.styles';

interface Props extends BoxProps {
  iconBoxProps?: FlexProps;
  textProps?: TextProps;
}

export const FooterPartners: React.FC<Props> = ({
  iconBoxProps,
  textProps,
  ...props
}) => {
  return (
    <Flex
      flexWrap="wrap"
      alignItems="center"
      gap={4}
      paddingRight={12}
      {...boxStyles}
      {...props}
    >
      <Flex alignItems="center" flexWrap="wrap" gap={2}>
        <IconBox
          boxSize="30px"
          icon={
            <InstagramNoBorderIcon
              aria-label={`${SOCIAL_PLATFORMS_NAMES.instagram} Partner`}
              boxSize="30px"
            />
          }
          {...iconBoxProps}
        />
        <IconBox
          boxSize="30px"
          icon={
            <TiktokNoBorderIcon
              aria-label={`${SOCIAL_PLATFORMS_NAMES.tiktok} Partner`}
              boxSize="30px"
            />
          }
          {...iconBoxProps}
        />
        <IconBox
          boxSize="30px"
          icon={
            <YoutubeNoBorderIcon
              aria-label={`${SOCIAL_PLATFORMS_NAMES.youtube} Partner`}
              boxSize="30px"
            />
          }
          {...iconBoxProps}
        />
        <IconBox
          boxSize="30px"
          icon={
            <SnapchatColorIcon
              aria-label={`${SOCIAL_PLATFORMS_NAMES.snapchat} Partner`}
              boxSize="30px"
            />
          }
          {...iconBoxProps}
        />
      </Flex>
      <Text {...textStyles} {...textProps}>
        Partners
      </Text>
    </Flex>
  );
};
