import {
  Center,
  Flex,
  FlexProps,
  Heading,
  HeadingProps,
} from '@chakra-ui/react';

import { PLATFORMS } from '~/constants/platforms';

import { FoamLogo } from './components/FoamLogo';
import { WhalarLogo } from './components/WhalarLogo';

interface HeaderLogoProps extends FlexProps {
  heading: React.ReactNode;
  headingProps?: HeadingProps;
  subHeading?: React.ReactNode;
  subHeadingProps?: HeadingProps;
  platform: PLATFORMS;
}

export function HeaderLogo({
  heading,
  headingProps = {},
  subHeading,
  subHeadingProps = {},
  platform,
  ...props
}: HeaderLogoProps) {
  const Logo = platform === PLATFORMS.foam ? FoamLogo : WhalarLogo;
  return (
    <Flex as="header" flexDirection="column" marginBottom={8} {...props}>
      <Center mb={6}>
        <Logo />
      </Center>

      <Heading
        as="h1"
        color="primary.500"
        fontSize={{ base: '3xl', md: '32px' }}
        fontWeight="semibold"
        lineHeight="1em"
        textAlign="center"
        {...headingProps}
      >
        {heading}
      </Heading>
      {subHeading && (
        <Heading
          color="primary.400"
          fontSize="md"
          fontWeight="normal"
          marginTop={4}
          textAlign="center"
          {...subHeadingProps}
        >
          {subHeading}
        </Heading>
      )}
    </Flex>
  );
}
