import { Container, ContainerProps, Flex } from '@chakra-ui/react';

import { FooterLinks } from './components/FooterLinks';
import { FooterPartners } from './components/FooterPartners';
import { FooterPlatforms } from './components/FooterPlatforms';

interface AuthorizationFooterProps extends ContainerProps {
  platform: string;
}

export const AuthorizationFooter: React.FC<AuthorizationFooterProps> = ({
  platform,
  ...props
}) => (
  <Container
    as="footer"
    backgroundColor="white"
    display="flex"
    justifyContent={{ base: 'center', md: 'center' }}
    maxWidth="inherit"
    paddingX={8}
    paddingY={6}
    position="relative"
    {...props}
  >
    <Flex justifyContent="space-between" alignItems="flex-end" width="full">
      <Flex gap={4} alignItems="end">
        <FooterPlatforms />
        <FooterLinks platform={platform} />
      </Flex>
      <FooterPartners
        padding={0}
        textProps={{
          color: 'primary.300',
          fontSize: 'sm',
          lineHeight: '4',
          fontWeight: 'normal',
        }}
      />
    </Flex>
  </Container>
);
