import { FlexProps, Flex } from '@chakra-ui/react';

import { FoamLogo, WhalarLogo } from '~/components/icons';

export const FooterPlatforms: React.FC<FlexProps> = ({ ...props }) => (
  <Flex gap={4} {...props}>
    <WhalarLogo color="primary.300" />
    <FoamLogo color="primary.300" />
  </Flex>
);
