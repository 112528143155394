import { BoxProps, TextProps } from '@chakra-ui/react';

export const boxStyles: BoxProps = {
  paddingTop: 3,
  paddingBottom: 4,
};

export const textStyles: TextProps = {
  color: 'lightBlack',
  fontSize: 'md',
  lineHeight: '14px',
  verticalAlign: 'bottom',
};
