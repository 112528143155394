import { Box, BoxProps, Flex, Link, Text } from '@chakra-ui/react';
import NextLink from 'next/link';

import { routes } from '~/constants/routes';

interface FooterLinksProps extends BoxProps {
  platform: string;
}

export const FooterLinks: React.FC<FooterLinksProps> = ({
  platform,
  ...props
}) => {
  const year = new Date().getUTCFullYear();

  return (
    <Box display="inline-flex" {...props}>
      <Flex alignItems="flex-end">
        <Text as="span" color="primary.300" fontSize="sm" lineHeight="4">
          @ {year} Whalar
        </Text>
        <Text marginLeft={2} color="primary.300" fontSize="sm" lineHeight="4">
          |
        </Text>

        <Link
          href={routes[platform].termsAndConditions}
          as={NextLink}
          color="primary.300"
          fontWeight="medium"
          marginLeft={2}
          fontSize="sm"
          lineHeight="4"
          target="_blank"
        >
          Website Terms
        </Link>

        <Text
          color="primary.300"
          fontWeight="medium"
          marginLeft={2}
          fontSize="sm"
          lineHeight="4"
        >
          |
        </Text>

        <Link
          href={routes[platform].privacyPolicy}
          as={NextLink}
          color="primary.300"
          fontWeight="medium"
          marginLeft={2}
          fontSize="sm"
          lineHeight="4"
          target="_blank"
        >
          Privacy
        </Link>
      </Flex>
    </Box>
  );
};
