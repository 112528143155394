import Head from 'next/head';

import { ChildrenType } from '~/types';

const defaultProps = {
  children: '',
};

const DocumentTitle: React.FC<ChildrenType> = ({ children }) => (
  <Head>
    <title key="document-title">{`${children}${
      children && ' - '
    }Powered by Whalar`}</title>
  </Head>
);

DocumentTitle.defaultProps = defaultProps;

export default DocumentTitle;
